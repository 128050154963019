import {
  addBlueClubs,
  addClubs,
  addYourClubs,
  listenToSelectedClub,
  listenToSelectedClubMembers,
  removeClub,
} from "../../features/club/clubReducer";
import {
  asyncActionError,
  asyncActionFinish,
  asyncActionStart,
} from "../async/asyncReducer";
import { unreadFile } from "../../features/club/clubDetailed/files/clubFilesReducer";
import { addClubsToSearch } from "../../features/search/searchReducer";

export const createClubApi = (club) => (dispatch) => {
  const bearer = "Bearer " + localStorage.getItem("token");

  return fetch(process.env.REACT_APP_API_BASE_URL + "clubs", {
    method: "POST",
    body: JSON.stringify(club),
    headers: {
      "Content-Type": "application/json",
      Authorization: bearer,
    },
    credentials: "same-origin",
  })
    .then(
      (response) => {
        if (response.ok) {
          return response;
        } else {
          var error = new Error(
            "Error " + response.status + ": " + response.statusText
          );
          error.response = response;
          throw error;
        }
      },
      (error) => {
        var errmess = new Error(error.message);
        throw errmess;
      }
    )
    .then((response) => response.json())
    .then((response) => {
      dispatch(listenToSelectedClub(response));
    })
    .catch((error) => {
      console.log("Create club ", error.message);
    });
};

export const fetchClubFeedApi = (query) => (dispatch) => {
  dispatch(asyncActionStart());

  const bearer = "Bearer " + localStorage.getItem("token");;

  return fetch(process.env.REACT_APP_API_BASE_URL + "club/feed" + query, {
    headers: {
      Authorization: bearer,
    },
  })
    .then(
      (response) => {
        if (response.ok) {
          return response;
        } else {
          var error = new Error(
            "Error " + response.status + ": " + response.statusText
          );
          error.response = response;
          throw error;
        }
      },
      (error) => {
        var errmess = new Error(error.message);
        throw errmess;
      }
    )
    .then((response) => response.json())
    .then((response) => {
      dispatch(asyncActionFinish());
      if (query.includes("learningXClub")) dispatch(addBlueClubs(response));
      else dispatch(addYourClubs(response));
    })
    .catch((error) => dispatch(asyncActionError(error.message)));
};

export const fetchClubsApi = (query) => (dispatch) => {
  dispatch(asyncActionStart());

  const bearer = "Bearer " + localStorage.getItem("token");
  const currentUser = localStorage.getItem("creds");

  return fetch(process.env.REACT_APP_API_BASE_URL + "clubs" + query, {
    headers: {
      Authorization: bearer,
    },
  })
    .then(
      (response) => {
        if (response.ok) {
          return response;
        } else {
          var error = new Error(
            "Error " + response.status + ": " + response.statusText
          );
          error.response = response;
          throw error;
        }
      },
      (error) => {
        var errmess = new Error(error.message);
        throw errmess;
      }
    )
    .then((response) => response.json())
    .then((response) => {
      dispatch(asyncActionFinish());
      if (query.includes("learningXClub")) dispatch(addBlueClubs(response));
      else if (query.includes(currentUser)) dispatch(addYourClubs(response));
      else if (query.includes("search")) dispatch(addClubsToSearch(response));
      else dispatch(addClubs(response));
    })
    .catch((error) => dispatch(asyncActionError(error.message)));
};

export const deleteClubApi = (clubId) => (dispatch) => {
  const bearer = "Bearer " + localStorage.getItem("token");

  return fetch(process.env.REACT_APP_API_BASE_URL + "clubs/" + clubId, {
    method: "DELETE",
    headers: {
      Authorization: bearer,
    },
    credentials: "same-origin",
  })
    .then(
      (response) => {
        if (response.ok) {
          return response;
        } else {
          var error = new Error(
            "Error " + response.status + ": " + response.statusText
          );
          error.response = response;
          throw error;
        }
      },
      (error) => {
        throw error;
      }
    )
    .then((response) => response.json())
    .then((response) => {
      console.log("Club Deleted", response);
      dispatch(removeClub(clubId));
    })
    .catch((error) => console.log(error.message));
};

export const updateClubApi = (club) => (dispatch) => {
  const bearer = "Bearer " + localStorage.getItem("token");

  return fetch(process.env.REACT_APP_API_BASE_URL + "clubs/" + club._id, {
    method: "PUT",
    body: JSON.stringify(club),
    headers: {
      "Content-Type": "application/json",
      Authorization: bearer,
    },
    credentials: "same-origin",
  })
    .then(
      (response) => {
        if (response.ok) {
          return response;
        } else {
          var error = new Error(
            "Error " + response.status + ": " + response.statusText
          );
          error.response = response;
          throw error;
        }
      },
      (error) => {
        var errmess = new Error(error.message);
        throw errmess;
      }
    )
    .then((response) => response.json())
    .then((response) => dispatch(listenToSelectedClub(response)))
    .catch((error) => {
      console.log("Club updated ", error.message);
      alert("Your Club could not be updated\nError: " + error.message);
    });
};

export const fetchSingleClubApi = (clubId) => (dispatch) => {
  dispatch(asyncActionStart());

  return fetch(process.env.REACT_APP_API_BASE_URL + "clubs/" + clubId)
    .then(
      (response) => {
        if (response.ok) {
          return response;
        } else {
          var error = new Error(
            "Error " + response.status + ": " + response.statusText
          );
          error.response = response;
          throw error;
        }
      },
      (error) => {
        var errmess = new Error(error.message);
        throw errmess;
      }
    )
    .then((response) => response.json())
    .then((response) => {
      if (!response) dispatch(asyncActionError("Club not found!"));
      else {
      dispatch(listenToSelectedClub(response));
      dispatch(asyncActionFinish());}
    })
    .catch((error) => {
      console.log(error.message);
      dispatch(asyncActionError("URL doesn't exist!"));
    });
};

export const requestToJoinClubApi = (member) => {
  const bearer = "Bearer " + localStorage.getItem("token");

  return fetch(
    process.env.REACT_APP_API_BASE_URL + "clubs/" + member.club + "/members",
    {
      method: "POST",
      body: JSON.stringify(member),
      headers: {
        "Content-Type": "application/json",
        Authorization: bearer,
      },
      credentials: "same-origin",
    }
  )
    .then(
      (response) => {
        if (response.ok) {
          return response;
        } else {
          var error = new Error(
            "Error " + response.status + ": " + response.statusText
          );
          error.response = response;
          throw error;
        }
      },
      (error) => {
        var errmess = new Error(error.message);
        throw errmess;
      }
    )
    .then((response) => response.json());
};

export const cancelClubMemberRequestApi = (clubId, memberId) => {
  const bearer = "Bearer " + localStorage.getItem("token");

  return fetch(
    process.env.REACT_APP_API_BASE_URL +
      "clubs/" +
      clubId +
      "/members/" +
      memberId,
    {
      method: "DELETE",
      headers: {
        Authorization: bearer,
      },
      credentials: "same-origin",
    }
  )
    .then(
      (response) => {
        if (response.ok) {
          return response;
        } else {
          var error = new Error(
            "Error " + response.status + ": " + response.statusText
          );
          error.response = response;
          throw error;
        }
      },
      (error) => {
        throw error;
      }
    )
    .then((response) => response.json())
    .then((response) => {
      console.log("Cancelled!", response);
    })
    .catch((error) => console.log(error.message));
};

export const SingleClubMemberApi = (clubId, userId) => (dispatch) => {
  const bearer = "Bearer " + localStorage.getItem("token");

  return fetch(
    process.env.REACT_APP_API_BASE_URL +
      "clubs/" +
      clubId +
      "/members/" +
      userId,
    {
      headers: {
        Authorization: bearer,
      },
    }
  )
    .then(
      (response) => {
        if (response.ok) {
          return response;
        } else {
          var error = new Error(
            "Error " + response.status + ": " + response.statusText
          );
          error.response = response;
          throw error;
        }
      },
      (error) => {
        var errmess = new Error(error.message);
        throw errmess;
      }
    )
    .then((response) => response.json());
};

export const countUnreadDiscussionsApi = (clubId) => (dispatch) => {
  const bearer = "Bearer " + localStorage.getItem("token");

  return fetch(
    process.env.REACT_APP_API_BASE_URL + "clubs/" + clubId + "/chatCount?club=true",
    {
      headers: {
        Authorization: bearer,
      },
    }
  )
    .then(
      (response) => {
        if (response.ok) {
          return response;
        } else {
          var error = new Error(
            "Error " + response.status + ": " + response.statusText
          );
          error.response = response;
          throw error;
        }
      },
      (error) => {
        var errmess = new Error(error.message);
        throw errmess;
      }
    )
    .then((response) => response.json());
};

export const countUnreadFilesApi = (clubId) => (dispatch) => {
  const bearer = "Bearer " + localStorage.getItem("token");

  return fetch(
    process.env.REACT_APP_API_BASE_URL + "clubs/" + clubId + "/filesCount",
    {
      headers: {
        Authorization: bearer,
      },
    }
  )
    .then(
      (response) => {
        if (response.ok) {
          return response;
        } else {
          var error = new Error(
            "Error " + response.status + ": " + response.statusText
          );
          error.response = response;
          throw error;
        }
      },
      (error) => {
        var errmess = new Error(error.message);
        throw errmess;
      }
    )
    .then((response) => response.json())
    .then((response) => dispatch(unreadFile(response.count)))
    .catch((error) => console.log(error.message));
};

export const fetchClubMembersApi = (clubId) => (dispatch) => {
  dispatch(asyncActionStart());

  const bearer = "Bearer " + localStorage.getItem("token");

  return fetch(
    process.env.REACT_APP_API_BASE_URL + "clubs/" + clubId + "/members",
    {
      headers: {
        Authorization: bearer,
      },
    }
  )
    .then(
      (response) => {
        if (response.ok) {
          return response;
        } else {
          var error = new Error(
            "Error " + response.status + ": " + response.statusText
          );
          error.response = response;
          throw error;
        }
      },
      (error) => {
        var errmess = new Error(error.message);
        throw errmess;
      }
    )
    .then((response) => response.json())
    .then((response) => {
      dispatch(listenToSelectedClubMembers(response));
      dispatch(asyncActionFinish());
    })
    .catch((error) => dispatch(asyncActionError(error.message)));
};

export const manageMembersApi = (member) => {
  const bearer = "Bearer " + localStorage.getItem("token");

  return fetch(
    process.env.REACT_APP_API_BASE_URL +
      "clubs/" +
      member.club +
      "/members/" +
      member._id,
    {
      method: "PUT",
      body: JSON.stringify(member),
      headers: {
        "Content-Type": "application/json",
        Authorization: bearer,
      },
      credentials: "same-origin",
    }
  )
    .then(
      (response) => {
        if (response.ok) {
          return response;
        } else {
          var error = new Error(
            "Error " + response.status + ": " + response.statusText
          );
          error.response = response;
          throw error;
        }
      },
      (error) => {
        var errmess = new Error(error.message);
        throw errmess;
      }
    )
    .then((response) => response.json())
    .catch((error) => {
      console.log("Club updated ", error.message);
      alert("Your Club could not be updated\nError: " + error.message);
    });
};

export const fetchClubMembersToAddMemberApi = (query) => (dispatch) => {
  dispatch(asyncActionStart());

  const bearer = "Bearer " + localStorage.getItem("token");

  return fetch(
    process.env.REACT_APP_API_BASE_URL + "clubs/" + query.club + "/addMembers",
    {
      method: "POST",
      body: JSON.stringify(query),
      headers: {
        "Content-Type": "application/json",
        Authorization: bearer,
      },
      credentials: "same-origin",
    }
  )
    .then(
      (response) => {
        if (response.ok) {
          return response;
        } else {
          var error = new Error(
            "Error " + response.status + ": " + response.statusText
          );
          error.response = response;
          throw error;
        }
      },
      (error) => {
        var errmess = new Error(error.message);
        throw errmess;
      }
    )
    .then((response) => response.json())
    .then((response) => {
      dispatch(listenToSelectedClubMembers(response));
      dispatch(asyncActionFinish());
    })
    .catch((error) => dispatch(asyncActionError(error)));
};
