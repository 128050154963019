import React, { useEffect } from "react";
import { Redirect, Route, useHistory, useLocation } from "react-router-dom";
import HomePage from "../../features/home/HomePage";
import Navbar from "../../features/nav/Navbar";
import ModalManager from "../common/modals/ModalManager";
import { toast, ToastContainer } from "react-toastify";
import PostDetailedPage from "../../features/posts/postDetailed/PostDetailedPage";
import ErrorComponent from "../common/errors/ErrorComponent";
import ProfilePage from "../../features/profile/profilePage/ProfilePage";
import AuthPage from "../../features/auth/AuthPage";
import ClubDashboard from "../../features/club/clubDashboard/ClubDashboard";
import EventForm from "../../features/events/eventForm/Eventform";
import EventDetailedPage from "../../features/events/eventDetailed/EventDetailedPage";
import ChatPage from "../../features/chat/ChatPage";
import { useSelector } from "react-redux";
import NotificationDashboard from "../../features/notification/NotificationDashboard";
import ReminderDashboard from "../../features/reminder/ReminderDashboard";
import CollegePage from "../../features/college/collegeDashboard/CollegePage";
import ClubAboutPage from "../../features/club/clubDetailed/about/ClubAboutPage";
import DiscussionPage from "../../features/club/clubDetailed/discussion/DiscussionPage";
import SessionDashboard from "../../features/club/clubDetailed/sessions/SessionDashboard";
import ManageClub from "../../features/club/clubDetailed/ManageClub";
import ClubMembersPage from "../../features/club/clubDetailed/member/ClubMembersPage";
import EditClubInfo from "../../features/club/clubForm/EditClubInfo";
import EditClubDescription from "../../features/club/clubForm/EditClubDescription";
import EditClubFaqs from "../../features/club/clubForm/EditClubFaqs";
import EventDetailsForm from "../../features/events/eventForm/EventDetailsForm";
import EventEligibilityForm from "../../features/events/eventForm/EventEligibiltyForm";
import EventRoundsForm from "../../features/events/eventForm/EventRoundsForm";
import EventDescriptionForm from "../../features/events/eventForm/EventDescriptionForm";
import EventRewardForm from "../../features/events/eventForm/EventRewardForm";
import PrivateRoute from "./PrivateRoute";
import ClubFilesDashboard from "../../features/club/clubDetailed/files/ClubFilesDashboard";
import ClubfaqsPage from "../../features/club/clubDetailed/faqs/ClubFaqsPage";
import ManageClubMembers from "../../features/club/clubDetailed/member/ManageClubMembers";
import CreateEventForm from "../../features/events/eventForm/CreateEventForm";
import FestivalPage from "../../features/festival/festivalDashboard/FestivalPage";
import SearchDashboard from "../../features/search/SearchDashboard";
import ScrollToTop from "./ScrollToTop";
import EventRegistrationForm from "../../features/events/eventForm/EventRegistrationForm";
import HeplPage from "../../features/help/HelpPage";
import EventRegisteredTeams from "../../features/events/eventDetailed/EventRegisteredTeams";
import AboutUsPage from "../../features/more/AboutUsPage";
import PrivacyPolicy from "../../features/more/PrivacyPolicy";
import TermsAndCondition from "../../features/more/TermsAndCondition";
import { useDispatch } from "react-redux";
import { checkMobileDivice } from "../../features/auth/authReducer";
import MobileNav from "../../features/nav/MobileNav";
import EventPage from "../../features/events/eventDashboard/EventPage";
import ClubMessageDashboard from "../../features/club/clubDetailed/messages/ClubMessageDashboard";
import ManageChannels from "../../features/club/clubDetailed/channel/ManageChannels";
import ChannelMembers from "../../features/club/clubDetailed/channel/ChannelMembers";
import LoginHomePage from "../../features/auth/LoginPage";
import EndUserLicense from "../../features/more/EndUserLicense";
import CloseAccount from "../../features/more/CloseAccount";
import CampusPage from "../../features/college/CampusPage";

export default function App() {
  const dispatch = useDispatch();
  const { key } = useLocation();
  const history = useHistory();
  const { isAuthenticated, os, isMobileDevice } = useSelector(
    (state) => state.auth
  );
  const { pathname } = useSelector((state) => state.router.location);

  const webAllowedPaths = [
    "/",
    "/about",
    "/privacy-policy",
    "/terms-conditions",
    "/events/:id",
    "/club/about/:id",
    "/club/fest/:id",
    "/posts/:id",
    "/error",
    "/eula",
    "/user/close-account/:id",
  ];

  const mobWebAllowedPaths = [
    "/about",
    "/privacy-policy",
    "/terms-conditions",
    "/eula",
    "/user/close-account/:id",
  ];

  function isPathAllowed(path, allowedPaths) {
    return allowedPaths.some((allowedPath) => {
      if (allowedPath.includes("/:id")) {
        const pathSegments = path.split("/");
        const allowedPathSegments = allowedPath.split("/");

        if (pathSegments.length === allowedPathSegments.length) {
          for (let i = 0; i < pathSegments.length; i++) {
            if (
              allowedPathSegments[i] !== ":id" &&
              pathSegments[i] !== allowedPathSegments[i]
            ) {
              return false;
            }
          }
          return true;
        } else {
          return false;
        }
      } else {
        return allowedPath === path;
      }
    });
  }

  useEffect(() => {
    if (!isAuthenticated && !isPathAllowed(pathname, webAllowedPaths)) {
      toast.error("Please login first!");
      history.push("/");
    }
    // eslint-disable-next-line
  }, [isAuthenticated, history, pathname]);

  useEffect(() => {
    let details = navigator.userAgent;
    let regexp = /android|iphone/i;
    let isAndroidExp = /android/i;
    let isIphoneExp = /iphone/i;
    let isMobile = regexp.test(details);
    let os = "desktop";
    if (isAndroidExp.test(details)) os = "android";
    if (isIphoneExp.test(details)) os = "iphone";
    if (isMobile) {
      dispatch(checkMobileDivice(os));
    }
  }, [dispatch]);

  if (isMobileDevice && !isPathAllowed(pathname, mobWebAllowedPaths))
    return window.location.assign(`https://m.clubchat.live${pathname}`);

  return (
    <div>
      {isMobileDevice ? (
        <div className="mobile-content">
          <ScrollToTop />
          <Route exact path="/about" component={AboutUsPage} />
          <Route exact path="/privacy-policy" component={PrivacyPolicy} />
          <Route exact path="/terms-conditions" component={TermsAndCondition} />
          <Route exact path="/eula" component={EndUserLicense} />
          <Route
            exact
            path="/user/close-account/:id"
            component={CloseAccount}
          />
          <MobileNav os={os} />
          <ModalManager />
        </div>
      ) : (
        <div>
          <Route exact path="/" component={LoginHomePage} />
          <Route exact path="/about" component={AboutUsPage} />
          <Route exact path="/privacy-policy" component={PrivacyPolicy} />
          <Route exact path="/terms-conditions" component={TermsAndCondition} />
          <Route exact path="/eula" component={EndUserLicense} />
          <div className="main-content">
            <ModalManager />
            <ToastContainer
              position="bottom-right"
              theme="colored"
              hideProgressBar
            />
            <Route
              path={"/(.+)"}
              render={() => (
                <div>
                  <div>
                    <ScrollToTop />
                    <PrivateRoute exact path="/feed" component={HomePage} />
                    <Route
                      exact
                      path="/posts/:id"
                      component={PostDetailedPage}
                    />
                    <Route exact path="/error" component={ErrorComponent} />
                    <Route exact path="/profile/:id" component={ProfilePage} />
                    <Route
                      exact
                      path="/notifications"
                      component={NotificationDashboard}
                    />
                    <Route
                      exact
                      path="/reminder"
                      component={ReminderDashboard}
                    />
                    <Route
                      exact
                      path={["/clubs", "/home"]}
                      component={ClubDashboard}
                    />
                    <Route exact path="/campus" component={CampusPage} />
                    <Route exact path="/college/:id" component={CollegePage} />
                    <Route
                      exact
                      path="/club/fest/:id"
                      component={FestivalPage}
                    />
                    <Route
                      path={"/club/(.+)"}
                      render={() => (
                        <>
                          <div>
                            <Route
                              exact
                              path={[
                                "/club/:clubId/about/:channelId",
                                "/club/about/:clubId",
                              ]}
                              component={ClubAboutPage}
                            />
                            <Route
                              exact
                              path="/club/:clubId/discussion/:channelId"
                              component={DiscussionPage}
                            />
                            <Route
                              exact
                              path="/club/:clubId/session/:channelId"
                              component={SessionDashboard}
                            />
                            <Route
                              exact
                              path="/club/:clubId/files/:channelId"
                              component={ClubFilesDashboard}
                            />
                            <Route
                              exact
                              path="/club/:clubId/messages/:channelId"
                              component={ClubMessageDashboard}
                            />
                            <Route
                              exact
                              path="/club/:clubId/faqs/:channelId"
                              component={ClubfaqsPage}
                            />
                            <Route
                              exact
                              path="/club/:clubId/member/:channelId"
                              component={ClubMembersPage}
                            />
                            <Route
                              exact
                              path="/club/:clubId/channel/member/:channelId"
                              component={ChannelMembers}
                            />
                          </div>
                        </>
                      )}
                    />
                    <Route
                      path={"/club/manage/(.+)"}
                      render={() => (
                        <>
                          <div>
                            <Route
                              exact
                              path="/club/manage/clubInfo/:id"
                              component={EditClubInfo}
                            />
                            <Route
                              exact
                              path="/club/manage/clubDescription/:id"
                              component={EditClubDescription}
                            />
                            <Route
                              exact
                              path="/club/manage/clubFaqs/:id"
                              component={EditClubFaqs}
                            />
                            <Route
                              exact
                              path="/club/manage/members/:id"
                              component={ManageClubMembers}
                            />
                            <Route
                              exact
                              path="/club/manage/channels/:id"
                              component={ManageChannels}
                            />
                          </div>
                          <ManageClub />
                        </>
                      )}
                    />
                    <Route exact path="/events" component={EventPage} />
                    <Route
                      path={["/event/create/(.+)", "/event/manage/(.+)"]}
                      key={key}
                      render={() => (
                        <>
                          <div>
                            <Route
                              exact
                              path={"/event/create/details"}
                              component={CreateEventForm}
                            />
                            <Route
                              exact
                              path={"/event/manage/details/:id"}
                              component={EventDetailsForm}
                            />
                            <Route
                              exact
                              path="/event/manage/eligibility/:id"
                              component={EventEligibilityForm}
                            />
                            <Route
                              exact
                              path="/event/manage/registration/:id"
                              component={EventRegistrationForm}
                            />
                            <Route
                              exact
                              path="/event/manage/rounds/:id"
                              component={EventRoundsForm}
                            />
                            <Route
                              exact
                              path="/event/manage/description/:id"
                              component={EventDescriptionForm}
                            />
                            <Route
                              exact
                              path="/event/manage/reward/:id"
                              component={EventRewardForm}
                            />
                          </div>
                          <EventForm />
                        </>
                      )}
                    />
                    <Route exact path="/chats" component={ChatPage} />
                    <Route exact path="/chats/:id" component={ChatPage} />
                    <Route
                      exact
                      path="/test"
                      render={() =>
                        !isAuthenticated ? <Redirect to="/" /> : <AuthPage />
                      }
                    />
                    <Route
                      exact
                      path="/events/:id"
                      component={EventDetailedPage}
                    />
                    <Route
                      exact
                      path="/events/teams/:id"
                      component={EventRegisteredTeams}
                    />
                    <Route exact path="/search" component={SearchDashboard} />
                    <Route exact path="/help" component={HeplPage} />
                    <Route
                      exact
                      path="/user/close-account/:id"
                      component={CloseAccount}
                    />
                  </div>
                  <Navbar />
                </div>
              )}
            />
          </div>
        </div>
      )}
    </div>
  );
}
