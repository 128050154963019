import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Card, Icon } from "semantic-ui-react";
import { fetchCollegeFestsApi } from "../../../app/apiRequest/collegeFestService";
import LoadingComponent from "../../../app/layout/LoadingComponent";

export default function FestDashboard({ college }) {
  const dispatch = useDispatch();
  const { fests } = useSelector((state) => state.college);
  const { loading, error } = useSelector((state) => state.async);

  useEffect(() => {
    if (fests.length > 0) return;
    dispatch(fetchCollegeFestsApi(college._id));
    // eslint-disable-next-line
  }, [dispatch, college]);

  if ((loading && !fests) || (!fests && !error))
    return <LoadingComponent content="Loading ..." />;

  return (
    <>
      <Card.Group>
        {fests.map((fest) => (
          <Card
            key={fest._id}
            image={fest.festImg}
            header={fest.festName}
            meta={fest.college.collegeName}
            description={<p className="three-lines">{fest.description}</p>}
            as={Link}
            to={`/club/fest/${fest._id}`}
            extra={
              <>
                <Icon name="calendar alternate outline" />
                {new Date(fest.startDate).toLocaleDateString()} -{" "}
                {new Date(fest.endDate).toLocaleDateString()}
              </>
            }
          />
        ))}
      </Card.Group>
    </>
  );
}
