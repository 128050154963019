import {
  createFest,
  deleteFest,
  fetchCollegeFests,
  listenToCollege,
  listenToFest,
  updateCollege,
  updateFest,
} from "../../features/college/collegeFestReducer";
import { addColleges } from "../../features/search/searchReducer";
import {
  asyncActionError,
  asyncActionFinish,
  asyncActionStart,
} from "../async/asyncReducer";

export const fetchCollegesApi = (query) => (dispatch) => {
  dispatch(asyncActionStart());

  return fetch(process.env.REACT_APP_API_BASE_URL + "college" + query)
    .then(
      (response) => {
        if (response.ok) {
          return response;
        } else {
          var error = new Error(
            "Error " + response.status + ": " + response.statusText
          );
          error.response = response;
          throw error;
        }
      },
      (error) => {
        var errmess = new Error(error.message);
        throw errmess;
      }
    )
    .then((response) => response.json())
    .then((response) => {
      dispatch(addColleges(response));
      dispatch(asyncActionFinish());
    })
    .catch((error) => dispatch(asyncActionError(error.message)));
};

export const createCollegeApi = (college) => (dispatch) => {
  const bearer = "Bearer " + localStorage.getItem("token");

  return fetch(process.env.REACT_APP_API_BASE_URL + "college", {
    method: "POST",
    body: JSON.stringify(college),
    headers: {
      "Content-Type": "application/json",
      Authorization: bearer,
    },
    credentials: "same-origin",
  })
    .then(
      (response) => {
        if (response.ok) {
          return response;
        } else {
          var error = new Error(
            "Error " + response.status + ": " + response.statusText
          );
          error.response = response;
          throw error;
        }
      },
      (error) => {
        var errmess = new Error(error.message);
        throw errmess;
      }
    )
    .then((response) => response.json())
    .catch((error) => {
      console.log("Create College ", error.message);
      alert("Your college could not be created\nError: " + error.message);
    });
};

export const fetchSingleCollegeApi = (collegeId) => (dispatch) => {
  return fetch(process.env.REACT_APP_API_BASE_URL + "college/" + collegeId)
    .then(
      (response) => {
        if (response.ok) {
          return response;
        } else {
          var error = new Error(
            "Error " + response.status + ": " + response.statusText
          );
          error.response = response;
          throw error;
        }
      },
      (error) => {
        var errmess = new Error(error.message);
        throw errmess;
      }
    )
    .then((response) => response.json())
    .then((response) => dispatch(listenToCollege(response)))
    .catch((error) => console.log(error.message));
};

export const updateCollegeApi = (college) => (dispatch) => {
  const bearer = "Bearer " + localStorage.getItem("token");

  return fetch(process.env.REACT_APP_API_BASE_URL + "college/" + college._id, {
    method: "PUT",
    body: JSON.stringify(college),
    headers: {
      "Content-Type": "application/json",
      Authorization: bearer,
    },
    credentials: "same-origin",
  })
    .then(
      (response) => {
        if (response.ok) {
          return response;
        } else {
          var error = new Error(
            "Error " + response.status + ": " + response.statusText
          );
          error.response = response;
          throw error;
        }
      },
      (error) => {
        var errmess = new Error(error.message);
        throw errmess;
      }
    )
    .then((response) => response.json())
    .then((response) => dispatch(updateCollege(response)))
    .catch((error) => {
      console.log("Club updated ", error.message);
      alert("Your Club could not be updated\nError: " + error.message);
    });
};

export const createCollegeFestApi = (fest) => (dispatch) => {
  const bearer = "Bearer " + localStorage.getItem("token");

  return fetch(process.env.REACT_APP_API_BASE_URL + "fests", {
    method: "POST",
    body: JSON.stringify(fest),
    headers: {
      "Content-Type": "application/json",
      Authorization: bearer,
    },
    credentials: "same-origin",
  })
    .then(
      (response) => {
        if (response.ok) {
          return response;
        } else {
          var error = new Error(
            "Error " + response.status + ": " + response.statusText
          );
          error.response = response;
          throw error;
        }
      },
      (error) => {
        var errmess = new Error(error.message);
        throw errmess;
      }
    )
    .then((response) => response.json())
    .then((response) => dispatch(createFest(response)))
    .catch((error) => {
      console.log("Create club ", error.message);
      alert("Your club could not be created\nError: " + error.message);
    });
};

export const fetchCollegeFestsApi = (college) => (dispatch) => {
  dispatch(asyncActionStart());

  const bearer = "Bearer " + localStorage.getItem("token");

  return fetch(process.env.REACT_APP_API_BASE_URL + "fests?college=" + college, {
    headers: {
      Authorization: bearer,
    },
  })
    .then(
      (response) => {
        if (response.ok) {
          return response;
        } else {
          var error = new Error(
            "Error " + response.status + ": " + response.statusText
          );
          error.response = response;
          throw error;
        }
      },
      (error) => {
        var errmess = new Error(error.message);
        throw errmess;
      }
    )
    .then((response) => response.json())
    .then((response) => {
      dispatch(fetchCollegeFests(response));
      dispatch(asyncActionFinish());
    })
    .catch((error) => dispatch(asyncActionError(error.message)));
};

export const updateCollegeFestApi = (fest) => (dispatch) => {
  const bearer = "Bearer " + localStorage.getItem("token");

  return fetch(process.env.REACT_APP_API_BASE_URL + "fests/" + fest._id, {
    method: "PUT",
    body: JSON.stringify(fest),
    headers: {
      "Content-Type": "application/json",
      Authorization: bearer,
    },
    credentials: "same-origin",
  })
    .then(
      (response) => {
        if (response.ok) {
          return response;
        } else {
          var error = new Error(
            "Error " + response.status + ": " + response.statusText
          );
          error.response = response;
          throw error;
        }
      },
      (error) => {
        var errmess = new Error(error.message);
        throw errmess;
      }
    )
    .then((response) => response.json())
    .then((response) => dispatch(updateFest(response)))
    .catch((error) => {
      console.log("Club updated ", error.message);
      alert("Your Club could not be updated\nError: " + error.message);
    });
};

export const deleteCollegeFestApi = (festId) => (dispatch) => {
  const bearer = "Bearer " + localStorage.getItem("token");

  return fetch(process.env.REACT_APP_API_BASE_URL + "fests/" + festId, {
    method: "DELETE",
    headers: {
      Authorization: bearer,
    },
    credentials: "same-origin",
  })
    .then(
      (response) => {
        if (response.ok) {
          return response;
        } else {
          var error = new Error(
            "Error " + response.status + ": " + response.statusText
          );
          error.response = response;
          throw error;
        }
      },
      (error) => {
        throw error;
      }
    )
    .then((response) => response.json())
    .then((response) => {
      console.log("Club Deleted", response);
      dispatch(deleteFest(festId));
    })
    .catch((error) => console.log(error.message));
};

export const fetchSingleCollegeFestApi = (festId) => (dispatch) => {
  return fetch(process.env.REACT_APP_API_BASE_URL + "fests/" + festId)
    .then(
      (response) => {
        if (response.ok) {
          return response;
        } else {
          var error = new Error(
            "Error " + response.status + ": " + response.statusText
          );
          error.response = response;
          throw error;
        }
      },
      (error) => {
        var errmess = new Error(error.message);
        throw errmess;
      }
    )
    .then((response) => response.json())
    .then((response) => dispatch(listenToFest(response)))
    .catch((error) => console.log(error.message));
};

export const handeleFollowFestApi = (fest, action) => (dispatch) => {
  const bearer = "Bearer " + localStorage.getItem("token");

  return fetch(process.env.REACT_APP_API_BASE_URL + "fests/" + action + "/" + fest._id, {
    method: "PUT",
    body: JSON.stringify(fest),
    headers: {
      "Content-Type": "application/json",
      Authorization: bearer,
    },
    credentials: "same-origin",
  })
    .then(
      (response) => {
        if (response.ok) {
          return response;
        } else {
          var error = new Error(
            "Error " + response.status + ": " + response.statusText
          );
          error.response = response;
          throw error;
        }
      },
      (error) => {
        var errmess = new Error(error.message);
        throw errmess;
      }
    )
    .then((response) => response.json())
    .then((response) => dispatch(updateFest(response)))
    .catch((error) => {
      console.log("Club updated ", error.message);
      alert("Your Club could not be updated\nError: " + error.message);
    });
};
