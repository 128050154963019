import { formatDistance } from "date-fns";
import React from "react";
import { Link } from "react-router-dom";
import { Feed } from "semantic-ui-react";

export default function NotificationListItem({ notification }) {
  var image = "/assets/user.png";
  if (notification.post) {
    image = notification.userBy.userImg;
  } else if (notification.club) {
    image = notification.club.clubImg;
  } else if (notification.event) {
    image = notification.event.eventImg;
  }

  var othersCount = 0;

  var summary = "";
  if (notification.post) {
    if (notification.msg === "liked your post.") {
      othersCount = notification.post.likes.length - 1;
    } else {
      othersCount = notification.post.commentsCount - 1;
    }
    if (othersCount > 0) {
      summary = `${notification.userBy.displayName} and ${othersCount} others ${notification.msg}.`;
    } else {
      summary = `${notification.userBy.displayName} ${notification.msg}.`;
    }
  } else if (notification.club && notification.userBy) {
    summary = `${notification.userBy.displayName} ${notification.msg}.`;
  } else if (notification.club && !notification.userBy) {
    summary = `${notification.club.clubName} ${notification.msg}.`;
  } else if (notification.event) {
    summary = `${notification.event.eventTitle} ${notification.msg}.`;
  } else {
    summary = notification.msg;
  }

  var link = "/clubs";
  if (notification.post) {
    link = `/posts/${notification.post._id}`;
  } else if (notification.club) {
    link = `/club/about/${notification.club._id}`;
  } else if (notification.event) {
    link = `/events/${notification.event._id}`;
  }

  return (
    <>
      <Feed>
        <Feed.Event>
          <Feed.Label>
            <img
              src={image}
              alt=""
              style={{
                verticalAlign: "middle",
                width: 40,
                height: 40,
                borderRadius: "50%",
              }}
            />
          </Feed.Label>
          <Feed.Content>
            <Feed.Date>
              {formatDistance(new Date(notification.updatedAt), new Date(), {
                addSuffix: true,
              })}
            </Feed.Date>
            <Feed.Summary as={Link} to={link}>
              {summary}
            </Feed.Summary>
            {notification.post && (
              <Feed.Extra images>
                {notification.post.images.map((postImg, i) => (
                  <img src={postImg} key={i} alt="" />
                ))}
              </Feed.Extra>
            )}
          </Feed.Content>
        </Feed.Event>
      </Feed>
    </>
  );
}
